import api from '../../utils/api'
import axios from 'axios'

export const state = {
  tutorial: {},
  tutorials: [],
  tutorialsMeta: {},
};

export const mutations = {
  SET_TUTORIAL(state, tutorial) {
    state.tutorial = tutorial;
  },
  SET_TUTORIALS(state, tutorials) {
    state.tutorials = tutorials;
  },
  SET_TUTORIALS_META(state, tutorialsMeta) {
    state.tutorialsMeta = tutorialsMeta;
  }
}

export const actions = {
  async handleSetTutorial({ commit }, tutorial) {
    commit('SET_TUTORIAL', tutorial);
  },
  async handleGetTutorial({ commit }, _id) {
    const { data: response } = await api.get(`/tutorials/${_id}`);

    if (response && response.data.tutorial) {
      const tutorial = response.data.tutorial
      commit('SET_TUTORIAL', tutorial);
    }

    return response
  },
  async handleGetTutorials({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
        sortBy: payload.sortBy || undefined,
        sortIn: payload.sortIn || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }
    const { data: response } = await api.get('/tutorials', {
      params
    });

    if (response && response.data.tutorials) {
      const tutorials = response.data.tutorials
      const meta = response.meta
      commit('SET_TUTORIALS', tutorials);
      commit('SET_TUTORIALS_META', meta);
    }
  },
  async handleGetTutorialsWithData({ commit }) {
    const { data: response } = await api.get('/tutorials');

    if (response && response.data.tutorials) {
      /*const tutorialsRaw = response.data.tutorials
      const tutorials = []

      for (let index in tutorialsRaw) {
        const { data } = await axios.get(tutorialsRaw[index].link.replace('v%', 'v='))

        const tutorial = {
          link: tutorialsRaw[index].link,
          data
        }

        tutorials.push(tutorial)
      }*/
      commit('SET_TUTORIALS', response.data.tutorials);
    }
  },
  async handleCreateTutorial({ state, commit }) {
    const tutorial = state.tutorial;

    const { data } = await api.post('/tutorials', tutorial);

    commit('SET_TUTORIAL', {});

    return data;
  },
  async handleUpdateTutorial({ state, commit }) {
    const tutorial = state.tutorial;

    const { data } = await api.put(`/tutorials/${tutorial._id}`, tutorial);

    commit('SET_TUTORIAL', {});

    return data;
  },
  async handleRemoveTutorial({ state, commit }) {
    const tutorial = state.tutorial;

    const { data } = await api.delete(`/tutorials/${tutorial._id}`);

    commit('SET_TUTORIAL', {});

    return data;
  }
}

export const getters = {
  getTutorial: (state) => state.tutorial,
  getTutorials: (state) => state.tutorials,
  getTutorialsMeta: (state) => state.tutorialsMeta,
}
